import React, { useEffect } from 'react'
import { Box, List, ListItem } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { DrivingLicenceAvatar, PersonAvatar, useDrawer } from 'src/mtska-frontend-app-component';
import { VehicleCard } from '../../Car/views';

const Driver = () => {
    const { t } = useTranslation();
    const { activate } = useDrawer();

    const { item: user } = useDataProvider('auth/token');
    const { loadAll: loadAllWhoAmI, items: myPerson } = useDataProvider('persons/person/whoami');
    const { loadAll: loadAllMyDocuments, items: myDocuments } = useDataProvider('persons/person/documents');
    
    const { loadAll: loadAllMyCars, items: myCars, total: myCarsTotal } = useDataProvider('cars/car');

    useEffect(() => {
        loadAllWhoAmI();
        loadAllMyDocuments();
        loadAllMyCars();
    }, [user]);

    const handlePersonClick = () => {
        activate('persons/detail', { id: myPerson.id });
    }

    return (
        <Box className="dashboard dashboard-driver">

            <Box className="dashboard-portion-title">{t('Your profile')}</Box>
            <Box onClick={handlePersonClick} className="dashboard-portion-driver-info">
                <PersonAvatar firstname={myPerson?.firstname} lastname={myPerson?.lastname} url={myPerson?.picture?.url} withname={true} detail={user?.data?.email} />
                <DrivingLicenceAvatar documents={myDocuments} />
            </Box>

            <Box className="dashboard-portion-title">{t('Your vehicles')}</Box>
            <Box className="dashboard-portion-subtitle">{t('You have {{myCarsTotal}} vehicles in your garage', { myCarsTotal: myCarsTotal })}</Box>

            <List className="dashboard-portion dashboard-menu">
                {
                    myCars.map((myCar, k) => (
                        <ListItem key={k} className="dashboard-portion-item">
                            <VehicleCard itemview={myCar} />
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    )

}

export default Driver;


