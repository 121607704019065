import React from 'react'
import { DateRangeFilter } from './dateRangeFilter/DateRangeFilter'
import { NumericRangeFilter } from './numericRangeFilter/NumericRangeFilter'
import { SelectOptionsFilter } from './selectOptionsFilter/SelectOptionsFilter'

export const CustomSelectionFilter = ({ applyValue, item, options, type, value }) => {
    if (['date', 'datetime'].includes(type)) {
        return <DateRangeFilter applyValue={applyValue} item={item} value={value} />
    } else if (type === 'currency') {
        return <NumericRangeFilter applyValue={applyValue} item={item} value={value} />
    } else if (options) {
        return <SelectOptionsFilter applyValue={applyValue} item={item} options={options} value={value} />
    }
}
