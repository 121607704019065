import React from 'react';
import { Box } from '@mui/joy';
import { Tag } from '../Snippet';
import { useTranslation } from 'react-i18next';

const CellGeneric = ({ type, value, field }) => {
    const { t } = useTranslation();
    return (
        <Box className={"list-element-cell cell-generics cell-generics-"+type}>
            {
                value ? (<span className="name" title={value[field]}>{value[field]}</span>) : (<Tag>{t('missing')}</Tag>)
            }
        </Box>
    )
    
}

export default CellGeneric;