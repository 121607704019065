import React from 'react'
import { DATA_PROVIDER_NAME } from './default.constants'
import { PianoContiReport } from '../../PianoContiReport/PianoContiReport'

const Default = () => {

    // todo ==> ripristinare parte sotto
    return (
        'Presto disponibile'
    )
    /* return (
        <PianoContiReport dataProviderName={DATA_PROVIDER_NAME} />
    ) */
}

export default Default
