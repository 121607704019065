import React, { useState, useRef, useEffect } from 'react';
import { useForm, useWatch } from "react-hook-form"
import * as XLSX from 'xlsx';
import { Box, Alert } from '@mui/joy';
import Previewer from './Previewer';
import { useTranslation } from 'react-i18next';
import { Button, Input, Text } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { XMLParser } from 'fast-xml-parser';
import * as forge from 'node-forge';
import * as asn1js from 'asn1js';
import { useXMLFlattener } from './XMLFlattener';
import { useXMLMapping } from './XMLMapping';
import WidgetDocumentPreview from 'src/mtska-frontend-app-component/components/FormElements/WidgetDocumentPreview';

const Importer = ({ dataProviderName, uid, handleClose, filters, externalDefaultMapping = {} }) => {

    const { t } = useTranslation();
    const { item: config } = useDataProvider('configs/config');
    const { save: saveImport, loadAll, errorMessage, errors, success } = useDataProvider('imports/job', true);
    const { loadAll: loadMappings, items: mappingsDb } = useDataProvider('imports/mapping');
    const { loadAll: loadLegalEntities, items: legalEntities } = useDataProvider('companies/legalentity');
    const { flattenItalianInvoiceXml } = useXMLFlattener();
    const { mapItalianInvoiceXml } = useXMLMapping();


    const [excelFile, setExcelFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [xmlFile, setXmlFile] = useState(null);
    const [error, setError] = useState(null);

    const [dataType, setDataType] = useState(null);
    const [data, setData] = useState(null);
    const [importStage, setImportStage] = useState(1);

    const [editing, setEditing] = useState(false);
    const [mapping, setMapping] = useState({});
    const [selectedMapping, setSelectedMapping] = useState();
    const [defaultMapping, setDefaultMapping] = useState(externalDefaultMapping);

    const [selectedMappingNewName, setSelectedMappingNewName] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [model, setModel] = useState();
    const [mappings, setMappings] = useState({});

    useEffect(() => {
        if (!Array.isArray(mappingsDb) || mappingsDb.length === 0) return;

        setMappings((prevMappings) => {
            const mappingValues = mappingsDb.reduce((a, v) => {
                return { ...a, [v.name]: v.mapping };
            }, {});

            // Controlla se i valori di mapping sono effettivamente cambiati
            if (JSON.stringify(prevMappings) !== JSON.stringify(mappingValues)) {
                return mappingValues;
            }
            return prevMappings; // Evita di cambiare lo stato se i valori sono identici
        });
    }, [mappingsDb]);

    useEffect(() => {
        loadMappings(filters);
        loadLegalEntities({
            'items': [{
                'field': 'type',
                'operator': 'equals',
                'value': 'customer'
            }]
        });
    }, []);

    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setModel(config.data.modules[dataProviderName].importModel);
    }, [config, dataProviderName])

    const form = useRef();
    const { handleSubmit, control, setValue } = useForm();
    const selectedMappingNewNameWatcher = useWatch({
        control,
        name: 'selectedMappingNewName'
    });

    const selectedMappingWatcher = useWatch({
        control,
        name: 'selectedMapping'
    });

    const selectedFileWatcher = useWatch({
        control,
        name: 'fileUploader'
    });

    useEffect(() => {
        setSelectedMappingNewName(selectedMappingNewNameWatcher);
    }, [selectedMappingNewNameWatcher])

    useEffect(() => {
        handleSelectedMapping(selectedMappingWatcher);
    }, [selectedMappingWatcher])

    useEffect(() => {
        if (document.querySelector('[name="fileUploader"]') === null) {
            return;
        }

        handleFile(document.querySelector('[name="fileUploader"]').files[0]);
    }, [selectedFileWatcher])

    useEffect(() => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {
                type: 'buffer',
                codepage: 65001, // Assicura la lettura con codifica UTF-8
                // cellText:false, 
                //cellDates:true,
                raw: true,
            });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            // https://docs.sheetjs.com/docs/api/utilities/array#array-output
            const data = XLSX.utils.sheet_to_json(worksheet, {
                defval: "",
                raw: false,
                // dateNF: 'DD"/"MM"/"yyyy', 
                // strip: false, 
                blankrows: true
            });

            setDataType('xls');
            setData(data);
            setImportStage(2);
        }
    }, [excelFile])

    useEffect(() => {
        if (!xmlFile) return;

        // Convert ArrayBuffer to string
        const decoder = new TextDecoder("utf-8");
        const xmlString = decoder.decode(xmlFile);

        // Parse XML using fast-xml-parser
        const parser = new XMLParser();
        const jsonData = parser.parse(xmlString);

        let jsonFlatData;
        let jsonMapping;
        switch (true) {
            case jsonData.hasOwnProperty('FatturaElettronica'):
                jsonFlatData = flattenItalianInvoiceXml(jsonData.FatturaElettronica);
                jsonMapping = mapItalianInvoiceXml();
                setDefaultMapping(jsonMapping);
                break;
            case jsonData.hasOwnProperty('ns3:FatturaElettronica'):
                jsonFlatData = flattenItalianInvoiceXml(jsonData['ns3:FatturaElettronica']);
                jsonMapping = mapItalianInvoiceXml();
                setDefaultMapping(jsonMapping);
                break;
            case jsonData.hasOwnProperty('p:FatturaElettronica'):
                jsonFlatData = flattenItalianInvoiceXml(jsonData['p:FatturaElettronica']);
                jsonMapping = mapItalianInvoiceXml();
                setDefaultMapping(jsonMapping);
                break;
        }
        
        if (!jsonFlatData) {
            return;
        }

        setDataType('xml');
        setData(jsonFlatData);
        setImportStage(2);

    }, [xmlFile])

    // --------
    // CARICAMENTO DEL FILE
    // --------
    const handleFile = (selectedFile) => {
        const validMIMExls = ['application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        const validMIMExml = ['text/xml'];
        const validMIMEpdf = ['application/pdf'];

        if (selectedFile && validMIMExls.includes(selectedFile.type)) {
            resetFiles()

            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setExcelFile(e.target.result);
            }

            setValue('filename', selectedFile.name);
        } else if (selectedFile && validMIMExml.includes(selectedFile.type)) {
            resetFiles()

            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setXmlFile(e.target.result);
            }

            setValue('filename', selectedFile.name);
        } else if (selectedFile && selectedFile.name.split('.').pop().toLowerCase() == 'p7m') {
            resetFiles(t('P7M file detected'))

            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
                setError(t('Parsing P7M file'));

                //const uint8Array = new Uint8Array(e.target.result);
                parseP7MFile(e.target.result);
            };

            setValue('filename', selectedFile.name);
        } else if (selectedFile && validMIMEpdf.includes(selectedFile.type)) {
            resetFiles()
            const url = URL.createObjectURL(selectedFile)
            const pdfFile = { extension: 'pdf', file: selectedFile, url }
            setPdfFile(pdfFile)
            setValue('filename', selectedFile.name)
            setData(pdfFile)
            setDataType('pdf')
            setImportStage(2)
        } else {
            const error = selectedFile ? t('Import source file must be a valid file type (xls, xlsx, csv, xml, pdf)') : null
            resetFiles(error)
        }
    }

    const resetFiles = (error = null) => {
        setError(error)
        setExcelFile(null)
        setXmlFile(null)
        setPdfFile(null)
    }

    const parseP7MFile = (buffer) => {
        const binaryString = String.fromCharCode.apply(null, new Uint8Array(buffer));
        const fkr = binaryString.match(/<\?xml[\s\S]*?<\/ns3:FatturaElettronica>/)[0];

        const encoder = new TextEncoder();
        const mtfkr = encoder.encode(fkr).buffer;
        setXmlFile(mtfkr);
        setError(null);
    }


    // --------
    // GESTIONE DEI DUE STATI DI SUBMIT DELLA FORM
    // --------
    const handleFileSubmit = (formdata) => {
        if (pdfFile) {
            const { file } = pdfFile
            const data = new FormData()
            
            data.append('file', file)
            data.append('model', dataProviderName)
            data.append('mapping', false)
            data.append('excelData', false)

            setEditing(true)
            saveImport(data).then((data) => handleClose(uid, data.item.data)).catch(() => console.error('catch submit'))

            setSubmitDisabled(true)
        } else if (Object.keys(mapping).length === 0) {
            setError(t('At least one column must be associated to a destinationfield'));
        } else {
            submit(formdata);
            setSubmitDisabled(true);
        }
    }


    const submit = (formdata) => {
        setEditing(true);

        const payload = { ...formdata, excelData: data, model: dataProviderName };
        console.info('Data in uscita', payload);

        saveImport(payload).then((data) => handleClose(uid, data.item.data)).catch(() => console.error('catch submit'))
    }

    // --------
    // SELEZIONE DI UNA RICETTA DI MAPPING ESISTENTE
    // la selezione di un mapping esistente deve resettare eventuali mapping manuali e caricare lo schema desiderato
    // --------
    const handleSelectedMapping = (v) => {

        const newDefaultMapping = getMappingFromId(v);
        if (typeof newDefaultMapping === 'undefined') {
            setSelectedMapping(null);
            setDefaultMapping({});
            return;
        }

        setSelectedMapping(v);
        setDefaultMapping(getMappingFromId(v).mapping ?? {})
    }

    const getMappingsOptions = () => {
        const options = Object.keys(mappingsDb).length > 0 ? Object.values(mappingsDb).map((v, k) => {
            return { value: v.id, label: v.fullName };
        }) : []
        return options;
    }

    const getMappingFromId = (id) => {
        return mappingsDb.find(obj => {
            return obj.id === id
        })
    }

    const handleOnMappingChange = (newMapping) => {
        setValue('mapping', newMapping);
        setMapping(newMapping);
    }


    return (
        <Box className="drawer-section-content">

            <Box className="importer">
                <Box className="importer-form-wrapper">

                    <Box ref={form} className="importer-form" >

                        <Input
                            type="hidden"
                            name="mapping"
                            defaultValue={JSON.stringify(mapping)}
                            control={control}
                            editing={true}
                        />

                        <Input
                            type="hidden"
                            name="filename"
                            defaultValue={null}
                            control={control}
                            editing={true}
                        />

                        {importStage === 1 && (
                            <Box className={((importStage === 1) ? "import-stage import-stage-visible" : "import-stage import-stage-hidden")}>
                                <Text level={"title-md"} className="sectionTitle">{t('Select a file to be imported')}</Text>
                                <Input
                                    label={'Upload a file'}
                                    type="file"
                                    name="fileUploader"
                                    control={control}
                                    editing={true}
                                />
                            </Box>
                        )}
                        {importStage === 2 && data && dataType == 'xls' && (
                            <Box className={"import-stage import-stage-visible"}>

                                <Text level={"title-md"} className="sectionTitle">{t('Select an existing mapping to be applied')} </Text>

                                <Box className="import-actions">
                                    {mappings && Object.keys(mappings).length > 0 && (
                                        <Input
                                            label={'Apply saved mapping'}
                                            type="select"
                                            name="selectedMapping"
                                            control={control}
                                            editing={true}
                                            addEmptyOption={true}
                                            options={getMappingsOptions()}
                                            translateOptions={false}
                                        />
                                    )}
                                    <Input
                                        label={selectedMapping ? 'Rename mapping as' : 'Save mapping as'}
                                        type="text"
                                        name="selectedMappingNewName"
                                        control={control}
                                        editing={true}
                                    />
                                    <Input
                                        label={'Save data for LegalEntity'}
                                        type="select"
                                        name="legal_entity_id"
                                        control={control}
                                        translateOptions={false}
                                        editing={true}
                                        options={
                                            Array.isArray(legalEntities) && legalEntities.map((legalentity, k) => (
                                                { value: legalentity.id, label: legalentity.name }
                                            ))
                                        }
                                    />
                                    <Box className="form-element" sx={{ maxWidth: '120px' }}>
                                        <Button variant="solid" className="importer-submit" onClick={() => { handleSubmit(handleFileSubmit)() }} disabled={submitDisabled}>{t('Import')}</Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>

                {error && (
                    <Alert className="importer-error">{error}</Alert>
                )}

                {importStage === 2 && data && (
                    dataType === 'pdf'
                        ? (
                            <WidgetDocumentPreview item={pdfFile} />
                        )
                        : (
                            <Previewer
                                data={data}
                                model={model}
                                defaultMapping={defaultMapping}
                                onMappingChange={handleOnMappingChange}
                            />
                        )
                )}

                {(dataType === 'xml' || dataType === 'pdf') && (
                    <Box className="form-element" sx={{ maxWidth: '120px', marginTop: '30px' }}>
                        <Button variant="solid" className="importer-submit" onClick={() => { handleSubmit(handleFileSubmit)() }} disabled={submitDisabled}>{t('Import')}</Button>
                    </Box>
                )}

            </Box>
        </Box>
    );
}

export default Importer;