import React, { useContext, useState } from 'react'
import { SPLIT_RECORD_CONSTANTS } from './splitRecord.constants'
import { getCurrentTotal } from './splitRecord.functions'
import { useDataProvider } from 'src/mtska-frontend-data-provider'
import { useInputFocus } from 'src/mtska-frontend-app-scaffolding/hooks/useInputFocus'
import { usePianoContiOptions, useRowManager } from './splitRecord.hooks'
import { useTranslation } from 'react-i18next'
import { AppContext } from 'src/mtska-frontend-app-scaffolding'
import { Alert, Box, Card, ModalClose, TabPanel, Tabs } from '@mui/joy'
import { BreadCrumb, Button, DetailTitle } from 'src/mtska-frontend-app-component'
import { SplitRecordRow } from './row/SplitRecordRow'

const SplitRecord = (params) => {

    const { handleClose, multipart, uid } = params

    const { action, config, model, modelPianoConti, title } = SPLIT_RECORD_CONSTANTS

    const [showError, setShowError] = useState(false)

    const { userCapabilities } = useContext(AppContext)

    const { doAction, errorMessage, item: dataSottopos = {}, saving } = useDataProvider(model, multipart)
    const { data: sottopos = {} } = dataSottopos
    const { description, id: idSottopos, pianoContiCE, totalValue } = sottopos
    const { _id: pianoContiCeId } = pianoContiCE || {}

    const { loadAll } = useDataProvider(modelPianoConti)

    const { inputRef, setIsInputFocused } = useInputFocus()

    const { options } = usePianoContiOptions({ loadAll })

    // todo ==> passare split già esistenti, da ciclare per assegnargli un idFE (se non gestito a BE)
    const { onAdd, onChange, onDelete, state } = useRowManager({ idSottopos, setShowError })
    const { data } = state
    const { splits } = data

    const { t } = useTranslation()

    const onSave = (data) => {
        doAction({ action, data, model })
            .then((data) => handleClose(uid, data.item.data))
            .catch(() => setShowError(true))
    }

    const canSave = userCapabilities && userCapabilities[model + '.edit']

    const currentTotal = getCurrentTotal({ splits })
    const difference = totalValue - currentTotal

    const splitRecordRowCommonProps = {
        canSave,
        config,
        inputRef,
        onAdd,
        onChange,
        onDelete,
        options,
        saving,
        setIsInputFocused
    }

    return <>
        <Box className="drawer-section-header">
            <ModalClose />
            <DetailTitle title={title} />
            <Box display="flex" justifyContent="end" flexGrow={1}>
                <Button disabled={!canSave || difference !== 0 || saving} label={t("Save")} onClick={() => onSave(data)} />
            </Box>
        </Box>
        <BreadCrumb />
        {showError && <Alert color="danger">{errorMessage}</Alert>}
        <Box className="drawer-section-content">
            <Card className="form-wrapper">
                <Tabs aria-label="Basic tabs" defaultValue={0} className="mtska-tabs" >
                    <TabPanel className="mtska-tab-panel">
                        <fieldset className="mtska-fieldset form-section-fields-content">
                            <Box display='grid' gap='20px'>
                                <SplitRecordRow
                                    editing={false}
                                    rowFields={{ description, pianoContiCeId, totalValue }}
                                    {...splitRecordRowCommonProps}
                                />
                                <Alert color='warning'>
                                    {`${t('Previous Total')}: ${totalValue}, `}
                                    {`${t('Current Total')}: ${currentTotal}, `}
                                    {`${t('Difference')}: ${Math.round((difference) * 100) / 100}`}
                                </Alert>
                                {splits.map((rowFields) => (
                                    <SplitRecordRow
                                        editing={!saving}
                                        key={rowFields.idFE}
                                        rowFields={rowFields}
                                        {...splitRecordRowCommonProps}
                                    />
                                ))}
                            </Box>
                        </fieldset>
                    </TabPanel>
                </Tabs>
            </Card>
        </Box>
    </>
}

export default SplitRecord
