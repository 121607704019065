import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/joy";
import { useNavigate } from 'react-router-dom';
import { useDataProvider } from "src/mtska-frontend-data-provider";
import { AppContext } from "src/mtska-frontend-app-scaffolding";

const Auth0LoginButton = ({ className }) => {

    const { error, getAccessTokenWithPopup } = useAuth0();

    const navigate = useNavigate();

    const { loadOne: loadConfig } = useDataProvider('configs/config')
    const { item, loadOne } = useDataProvider('auth/token')

    const { setUserCapabilities, userCapabilities, appConfiguration } = useContext(AppContext)

    useEffect(() => {
        if (typeof item?.token === 'undefined') return;
        sessionStorage.setItem('token', item?.token)
    }, [item, navigate])

    const login = () => {
        (async () => {
            try {
                const t = await getAccessTokenWithPopup({
                    authorizationParams: {
                        audience: appConfiguration.auth0.AUDIENCE
                    }
                })
                if (t !== null) {
                    sessionStorage.setItem('token', t)
                    loadOne(t);
                    loadConfig(t).then((response) => {
                        if (response && response.item && response.item.data) {
                            setUserCapabilities(response.item.data.capabilities);
                        }
                    });
                }
            } catch (e) { }
        })()
    }

    useEffect(() => {
        if (Object.keys(userCapabilities).lenght > 0) {
            navigate(
                process.env.REACT_APP_AUTH0_DEFAULT_AUTHENTICATED_URI ||
                appConfiguration.auth0.DEFAULT_AUTHENTICATED_URI,
                 { replace: true }
                );
        }
    }, [userCapabilities])


    return <>
        {error && error.messages}
        <Button
            size="regular"
            variant="solid"
            className={className}
            onClick={login}
        >
            Accedi
        </Button>
    </>
}

export default Auth0LoginButton;