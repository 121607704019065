import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'


const RelationshipAttributionRule = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();

    return (
        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                {
                    v.contoEconomicoRecordField && (
                        <Box className="coupled">
                            <Box className="label">{t('field')}</Box>
                            <Box className="value">{v.contoEconomicoRecordField}</Box>
                        </Box>
                    )
                }

                {
                    v.cashFlowRecordField && (
                        <Box className="coupled">
                            <Box className="label">{t('field')}</Box>
                            <Box className="value">{v.cashFlowRecordField}</Box>
                        </Box>
                    )
                }

                <Box className="coupled">
                    <Box className="label">{t('operator')}</Box>
                    <Box className="value">{t(v?.operator)}</Box>
                </Box>

                <Box className="coupled">
                    <Box className="label">{t('comparator')}</Box>
                    <Box className="value">{v?.comparator}</Box>
                </Box>
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipAttributionRule



