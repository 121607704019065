import React, { useContext } from 'react';
import { Text } from '../Typography';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const ActiveModuleName = ({ currentModuleScope, currentModuleName, logo }) => {
    const { t } = useTranslation();

    const { userCapabilities } = useContext(AppContext);

    return (
        userCapabilities && Object.keys(userCapabilities).length > 0 && !userCapabilities['auth/role.is_driver'] ? (
            <div className="modulename">
                {logo && <div className='logo' dangerouslySetInnerHTML={{ __html: logo }} />}
                <Text level="h2">{(currentModuleScope) && (<span className="scope">{t(currentModuleScope)}</span>)}{t(currentModuleName)}</Text>
            </div>
        ) : (
            <div className="modulename">
                {logo && <div className='logo' dangerouslySetInnerHTML={{ __html: logo }} />}
                <Text level="h2">{t('My')} <span className="scope">{t(' Mobility')}</span></Text>
            </div>
        )
    );
}

export default ActiveModuleName;