import React from "react"
import { Box, ModalClose } from "@mui/joy"
import {  Text } from "src/mtska-frontend-app-component";
import { Importer } from "../components";
import { useTranslation } from 'react-i18next';
const wizardAction = 'create';

const Create = (params) => {
    const { t } = useTranslation();

   return <>
      {params.isDrawer && (
         <Box className="drawer-section-header" >
            <ModalClose />
            <Text level="title-md" icon >{t("New Import")}</Text>
         </Box>
      )}
      <Importer
         {...params}
         // filters={} 
         />
   </>

}

export default Create;


