import React, {useMemo} from 'react';
import { Box } from '@mui/joy';
import { KPI } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return useMemo(() => <>
        <Box className="mtska-chart-wrapper">

            <KPI
                title="Reports"
                icon="faChartSimple"
                dataProviderName={'statistics/total/countmine/bis/bi'}
            />
        </Box>
        <List />
    </>,[])

}

export default Default;


