import React, { useState, useEffect, useContext } from "react";
import { AppMenu, ActiveAppName, UserMenu, Debugger, LegalEntitySelector } from '../../../mtska-frontend-app-component';
import { AppContext } from '../../contexts';
import Minimizer from "./Minimizer";
import { Box } from '@mui/joy'

const Aside = ({ onOpenLayout }) => {

    const { filterByLegalEntity, innerWidth } = useContext(AppContext)
    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        setIsOpen((sessionStorage.getItem('prefersAsideOpen') === 'true') ?? true);
        onOpenLayout();
    }, [])

    const [isMouseInside, setIsMouseInside] = useState(false);

    const handleIsOpen = () => {
        sessionStorage.setItem('prefersAsideOpen', !isOpen)
        setIsOpen(!isOpen);
        onOpenLayout();
    }

    const handleMouseEnter = () => {
        setIsMouseInside(true);
    }

    const handleMouseLeave = () => {
        setIsMouseInside(false);
    }

    return (
        <aside className={(isOpen) ? 'open' : 'closed'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ActiveAppName />
            <Debugger />
            <LegalEntitySelector />
            <AppMenu />
            {innerWidth > 600 && (
                <Box sx={{ bottom: 20, position: 'absolute' }}>
                    <UserMenu />
                </Box>
            )}
            <Minimizer onClick={handleIsOpen} visible={isMouseInside} open={isOpen} />
        </aside>
    );
}

export default Aside;