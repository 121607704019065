import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DrawerButton, List as ListComponent, useDrawer } from 'src/mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'imports/job';
const drawerRouteBase = 'imports';
const wizardAction = 'destroy';

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const getselected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name, value]) => ({ name, value }))
    }

    const { doAction, loadAll, items, total, loading, download, downloading, reloadCounter } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }

    return (
        <>
            <ListComponent
                {...params}
                onRowDoubleClick={handleRowDoubleclick}
                onFilterPageSortChange={handleFilterPageSortChange}
                items={items}
                total={total}
                loading={loading}
                apiRef={apiRef}
                enableSelection
                rightButtons={[
                    // <Button disabled={!userCapabilities[dataProviderName + '.archive']}
                    //     icon={"faTrash"}
                    //     onClick={() => {
                    //         activate('imports/archive', { filters: getselected() });
                    //     }} 
                    // />,
                    <DrawerButton
                        drawerRoute={'imports/create'}
                        params={{
                            detailStack: params.detailStack ?? {},
                            dataProviderName: params.currentDataProviderName,
                            moduleName: params.moduleName,
                            filters: params.filters,

                        }}
                        callback={handleFilterPageSortChange}
                        label={t("New Import")} 
                    />,
                ]}
                dataProviderName={dataProviderName}
                title={t("Import")} 
                doAction={doAction}
            />
        </>
    );
}

export default List;


