import React, { useMemo } from 'react';
import { Box } from '@mui/joy';
import { KPI, Scorecard } from 'src/mtska-frontend-app-component';
import List from './List';

const Default = () => {
    const date = new Date();
    const formattedDateToday = date.toISOString().split('T')[0];
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const formattedDateStartOfMonth = startOfMonth.toISOString().split('T')[0];
    
    
    return <>
        <Box className="mtska-chart-wrapper">
            <KPI
                title="Leads"
                icon="faPeople"
                dataProviderName={'statistics/item/count/opportunities/lead'}
            />
            <KPI
                title="Leads current month"
                icon="faCalendar"
                dataProviderName={'statistics/currentmonth/count/opportunities/lead'}
                filters={{
                    'items': [{
                        'field': 'registered_at',
                        'operator': 'after',
                        'value': formattedDateStartOfMonth
                    }]
                }}
            />
            <KPI
                title={"Leads today"}
                icon="faCalendarStar"
                dataProviderName={'statistics/currentday/count/opportunities/lead'}
                filters={{
                    'items': [{
                        'field': 'registered_at',
                        'operator': 'after',
                        'value': formattedDateToday
                    }]
                }}
            />

            {/* <Scorecard
                title="Leads mese corrente"
                score={1577}
                delta={12.4}
                series={[
                    {
                        name: "Lead mese",
                        data: [1542, 1509, 1577]
                    }
                ]}
                scoreFormatStyle={'decimal'}
                scoreFormatUnit={''}
                scoreFormatCurrency={''}
            /> */}

            {/* <Scorecard
                title="Leads oggi"
                score={1}
                delta={-82.4}
                series={[
                    {
                        name: "Lead giorno",
                        data: [15, 21, 1]
                    }
                ]}
                scoreFormatStyle={'decimal'}
                scoreFormatUnit={''}
                scoreFormatCurrency={''}
            /> */}
        </Box>
        <List />
    </>

}

export default Default;


