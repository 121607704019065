import React, { useContext } from "react";
import { Box } from '@mui/joy';
import { Icon, KPI } from 'src/mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { Elletre, ImprenditoreVero, FleetManager, Driver } from ".";

const Default = () => {
    const { t } = useTranslation();
    const { appConfiguration, userCapabilities } = useContext(AppContext);

    const getView = () => {
        switch (appConfiguration.dashboard.view) {
            case 'elletre':
                return <Elletre />;
            case 'imprenditorevero':
                return <ImprenditoreVero />;
            case 'fleetmanager':
                if (userCapabilities && userCapabilities['cars/car.seeonlymine']) {
                    return <Driver />
                } else {
                    return <FleetManager />;
                }
        }
    }

    return (
        <Box className={appConfiguration.dashboard.view}>
            {
                (userCapabilities && userCapabilities['cars/car.seeonlymine']) ? (
                    <h1 style={{ marginTop: 0, color:'var(--app-main)' }}>{t('Welcome to MyMobility dashboard')}</h1>
                ) : (
                    <h1 style={{ marginTop: 0, color:'var(--app-main)' }}>{appConfiguration.dashboard.title}</h1>
                )
            }
            <p  style={{ marginTop: 0, color:'var(--app-main-subdued)' }}>{appConfiguration.dashboard.subtitle}</p>
            {getView()}
        </Box>
    )

}

export default Default;


