import React, { useState } from 'react'
import { pick } from 'dot-object'
import { useDataProvider } from 'src/mtska-frontend-data-provider'
import { Autocomplete, Box, CircularProgress } from '@mui/joy'
import { Cell } from '.'

let optionsCache = []

export const CellRelationship = (props) => {

    const {
        action,
        configFilters: items,
        field,
        optionKey,
        optionLabel,
        optionsDataProviderName,
        processRowUpdate,
        row = {},
        type,
        value = {}
    } = props

    const { loadAll } = useDataProvider(optionsDataProviderName)

    const [currentValue, setCurrentValue] = useState(value || {})
    const [editMode, setEditMode] = useState(false)
    const [options, setOptions] = useState([])

    const onChange = (_event, value) => {
        setCurrentValue(value)
        const updatedRow = { ...row, [field]: value }
        processRowUpdate(updatedRow, row, action)
        setEditMode(false)
    }

    const onFocus = () => {
        if (optionsCache.length > 0) {
            setOptions(optionsCache)
        } else if (options.length === 0) {
            setOptions(undefined)
            loadAll({ items }).then((data = {}) => {
                const { items = {} } = data
                const { data: options = [] } = items
                setOptions(options)
                optionsCache = options
            })
        }
    }

    return (
        <Box className={"list-element-cell cell-contract cell-contract-" + type}>
            {editMode
                ? (
                    <Autocomplete
                        endDecorator={!options && <CircularProgress size="sm" />}
                        // getOptionKey={(option) => pick(optionKey, option)}
                        getOptionLabel={(option) => option && pick(optionLabel, option) ? pick(optionLabel, option) : 'Nessuna selezione'}
                        loading={!options}
                        onChange={onChange}
                        onClose={() => setEditMode(false)}
                        onFocus={onFocus}
                        options={options || []}
                        sx={{ width: '100%' }}
                        value={currentValue}
                        variant='plain'
                    />
                )
                : (
                    <div onClick={() => setEditMode(true)} style={{ height: '100%', width: '100%' }}>
                        <Cell {...props} />
                    </div>
                )
            }
        </Box>
    )
}
