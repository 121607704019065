import React, {useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Status Management';
const wizardAction = 'statusManagement';
const wizardObject = 'Status';


const StatusManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const { 
        item, 
        errorMessage, 
        errors, 
        saving, 
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const options = [
        { label: "in_configuration", value: "in_configuration" },
        { label: "in_offer", value: "in_offer" },
        { label: "in_order", value: "in_order" },
        { label: "running", value: "running" },
        { label: "closed", value: "closed" }
    ];

    const filterStatuses = (statuses, currentStatus) => {
        let foundCurrent = false;
        return statuses.reduce((acc, status) => {
            if (status.value === currentStatus) {
                foundCurrent = true;
            }
            if (foundCurrent) {
                acc.push(status);
            }
            return acc;
        }, []);
    };
    const filteredOptions = filterStatuses(options, item?.data?.status || "in_configuration");

    const detailConfig = {
        "tabs": [
            {
                "name": "management",
                "fieldsets": [
                    {
                        "name": "management",
                        "fields": {
                            "status": {
                                "type": "select",
                                "options": filteredOptions,
                                "required": true,
                                "className": "col-12",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "statusActivation": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "right_now",
                                        "value": "right_now"
                                    },
                                    {
                                        "label": "in_future",
                                        "value": "in_future"
                                    }
                                ],
                                "className": "col-4",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "statusFuture_at": {
                                "type": "date",
                                "className": "col-8",
                                "icon": "faCalendarHeart",
                                "mindate": new Date().toISOString().split('T')[0],
                                "readonlycondition": [
                                    [
                                        "statusActivation",
                                        "!=",
                                        "in_future"
                                    ]
                                ]
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
        
    const handleSave = (data) => {
        doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return <>
    {/* <span>PARENT</span>
    <textarea>{JSON.stringify(params?.parent)}</textarea>
    <span>ITEM</span>
    <textarea>{JSON.stringify(item?.data)}</textarea> */}
        <DetailComponent
            values={{ "id": item?.data?._id, "status": item?.data?.status, "statusFuture_at": new Date().toISOString().split('T')[0] }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default StatusManagement;


