import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { LegalEntityAvatar, Tag, VehicleAvatar } from "../../../../mtska-frontend-app-component/components/Snippet";
import { Icon } from "src/mtska-frontend-app-component/assets";
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Text } from "../../../../mtska-frontend-app-component/components/Typography";
import { useDrawer } from "../../../../mtska-frontend-app-component/components/Drawer";
import { AppContext } from "src/mtska-frontend-app-scaffolding";
import { Button } from "../../../../mtska-frontend-app-component/components/Button";
import { useDataProvider } from "src/mtska-frontend-data-provider";

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const VehicleCard = ({ itemview, size = "standard" }) => {
    const { t } = useTranslation();
    const { formatDate, formatDistance } = useFormatter();
    const { activate } = useDrawer();
    const { item: car, loadOne } = useDataProvider(dataProviderName);
    const [item, setItem] = useState();

    useEffect(() => {
        loadOne(itemview?._id);
    }, []);

    useEffect(() => {
        if (!car) return;
        setItem(car.data);
    }, [car]);

    const handleCardClick = () => {
        if (item.status == 'running') {
            activate(drawerRouteBase + '/detail', { id: item.id });
        }
    }

    const handleAddTrack = () => {
        activate('tracekms/create', { car: item, legalEntity: item.legalEntity, numberOfTrackFields: 1 });
    }

    const tagcolors = {
        installed: "success",
        running: "success",
        in_order: "secondary",
        in_offer: "secondary",
        in_configuration: "secondary",
        closed: "danger",
        preleasing: "warning",
        substitute: "warning",
        archived: "danger"
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'running';
    }

    const { appConfiguration } = useContext(AppContext);

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const getTraceStatus = () => {
        if (item.status != 'running') return 'neutral';

        const traced = new Date(item?.traceKmsLast?.traced_at);
        const td = new Date();
        const nexttraced = addDays(traced, parseInt(appConfiguration.custom.traceKmMaxMissingDays));

        const awaitenlapsed = Math.floor((td - traced) / (1000 * 60 * 60 * 24));
        const awaitnext = Math.floor((nexttraced - traced) / (1000 * 60 * 60 * 24));

        const awaitenlapsedpercentage = awaitenlapsed / awaitnext;

        let color = "neutral";
        if (awaitenlapsedpercentage >= 1) {
            color = "danger"
        } else if (awaitenlapsedpercentage >= 0.7) {
            color = "warning"
        } else if (awaitenlapsedpercentage < 0.7) {
            color = "success"
        }
        //console.info('qui', traced, td, nexttraced, awaitenlapsed, awaitnext, awaitenlapsedpercentage, color);

        return color;
    }

    const getDeliveryDate = () => {
        let deliveryDate = null;
        if (item?.contract?.deliveryDelayed3_at) {
            deliveryDate = item?.contract?.deliveryDelayed3_at;
        } else if (item?.contract?.deliveryDelayed2_at) {
            deliveryDate = item?.contract?.deliveryDelayed2_at;
        } else if (item?.contract?.deliveryDelayed1_at) {
            deliveryDate = item?.contract?.deliveryDelayed1_at;
        } else if (item?.contract?.estimatedDelivery_at) {
            deliveryDate = item?.contract?.estimatedDelivery_at;
        }

        return formatDate(deliveryDate);
    }

    return (
        item && <Box className={"vehicle-card-wrapper"}>
            <Box className={"vehicle-card vehicle-avatar-" + size}>
                <Box className={item.status == 'running' && "vehicle-card-clickable"} onClick={handleCardClick}>

                    <VehicleAvatar url={item.picture?.url} fullname={item?.setup?.make + " " + item?.setup?.model} detail={item?.setup?.setup} withname={true} />
                    {item.status == 'running' && (
                        <>
                            <Icon icon='faArrowUpRightFromSquare' className="clickableIcon" />
                            <Box className="info">
                                <LegalEntityAvatar name={item?.contract?.renter?.name} url={item?.contract?.renter?.logo?.url} size="small" withname={true} detail={item?.contract?.renter?.telephone ?? t('No telephone registered')} />
                            </Box>
                        </>
                    )}
                    {item.status == 'in_order' && (
                        <>
                            <Box className="info">
                                <LegalEntityAvatar name={item?.contract?.renter?.name} url={item?.contract?.renter?.logo?.url} size="small" withname={true} />
                            </Box>

                            <Box className="info">
                                <p className="label">{t('estimatedDelivery_at')}</p>
                                <p className="value">{ getDeliveryDate() }</p>
                            </Box>
                        </>
                    )}
                    <Box className="info">
                        <p className="label">{t('plate')}</p>
                        <p className="value">{item?.plate || (<Tag color={"warning"} title={t("No plate yet assigned")}><Icon icon="faWarning" />{t('No plate yet assigned')}</Tag>)}</p>
                    </Box>

                    <Box className="info">
                        <p className="label">{t('status')}</p>
                        <Tag color={(tagcolors[getStatus(item)])} size="small" label={t(getStatus(item))} />
                    </Box>

                    {item.status != 'in_order' && (
                        <Box className="info highlight">
                            {
                                item?.contract?.start_at && (
                                    <Text icon="faArrowRightFromLine" className="info-contract">{formatDate(item?.contract?.start_at)}</Text>
                                )
                            }

                            {
                                item?.contract?.end_at && (
                                    <Text icon="faArrowRightToLine" className="info-contract">{formatDate(item?.contract?.end_at)}</Text>
                                )
                            }

                            {
                                item?.contract?.contractDuration && (
                                    <Text icon="faHourglass" className="info-contract">{item?.contract?.contractDuration} {t('months')}</Text>
                                )
                            }

                            {
                                item?.contract?.contractMileage && (
                                    <Text icon="faRoad" className="info-contract">{formatDistance(item?.contract?.contractMileage)}</Text>
                                )
                            }
                        </Box>
                    )}
                </Box>
                {item.status == 'running' && (
                    //SE oggi minore di 6 mesi da scadenza revisione strip rossa con countdown
                    <></>
                )}
                <Box className={"info info-tracekm info-tracekm-" + getTraceStatus()}>
                    {item.status == 'running' && (
                        <Button className="addTrack" onClick={handleAddTrack} variant={"solid"} icon={getTraceStatus() != 'success' ? "faWarning" : 'faPlus'}></Button>
                    )}
                    <p className="label">{t('Last recorded mileage')}</p>
                    <p className="value primary">{formatDistance(item.traceKmsLast?.value)}</p>
                    <p className="label">{t('Last recorded date')}</p>
                    <p className="value secondary" title={item?.setup?.setup}>{formatDate(item?.traceKmsLast?.traced_at)}</p>
                </Box>
            </Box>
        </Box>
    )
}

export default VehicleCard;