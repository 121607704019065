import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Button, List as ListComponent, Detail as DetailComponent, Confirm, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import {useLoadOne} from "src/mtska-frontend-data-provider/hooks/useLoadOne.hooks";

const dataProviderName = 'imports/job';

const Detail = (params) => {
    const { t } = useTranslation();
    const { close: closeDrawer } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const {modelName, detailConfig} = existingModules[dataProviderName]


    // DATA PROVIDER
    const {doAction, item, save, loadOne, success} = useDataProvider(dataProviderName, params.multipart);


    const [confirmDelete, setConfirmDelete] = useState()
    const handleImportAll = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Import")}
                okcallable={() => {
                    alert('import all ' + params.filters?._id)
                    doAction({
                        'action': 'import',
                        'model': 'Imports/Job',
                        'data': [params.filters?._id]
                    });
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Import all")}>
                {t("Are you sure you want to import all records?")}
            </Confirm>
        );
    }

    const handleImportSelected = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Import")}
                okcallable={() => {
                    doAction({
                        'action': 'import',
                        'model': 'Imports/Job',
                        'data': selected
                    });
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Import all")}>
                {t("Are you sure you want to import selected records?")}
            </Confirm>
        );
    }

    // const apiRef = useGridApiRef();

    const [selected, setSelected] = useState([]);
    const onRowSelectionModelChange = (selected) => {
        setSelected(selected);
    }

    const { loadAll, items, total, loading } = useDataProvider('imports/data');
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useMemo(() => {
        const filterModel = {
            ...listFilterPageSort?.filterModel ?? {},
            items: [...listFilterPageSort?.filterModel?.items ?? [], {
                operator: "equals",
                field: "job_id",
                value: params.id
            }]
        };
        
        loadAll && loadAll(filterModel, listFilterPageSort?.sortModel, listFilterPageSort?.paginationModel?.page + 1, listFilterPageSort?.paginationModel?.pageSize);
        
    }, [listFilterPageSort, params.filters]);

    return (
        <>
            <ListComponent
                {...params}
                onFilterPageSortChange={handleFilterPageSortChange}
                items={items}
                total={total}
                loading={loading}
                onRowSelectionModelChange={onRowSelectionModelChange}
                dynamicColumns={true}
                // apiRef={apiRef}
                enableSelection
                dataProviderName='imports/data'
                title={"Import data"}
                // leftButtons={[]}
                rightButtons={[
                    <Button
                        disabled={selected.length === 0}
                        onClick={() => {
                            handleImportSelected();
                        }}> Import selected</Button>,
                    <Button onClick={() => {
                        handleImportAll();
                    }}> Import all</Button>
                ]}
            />
            {confirmDelete}
        </>

    )
}

export default Detail;



