import React, { useState } from 'react'

const AppContext = React.createContext({
    currentModuleName: 'noname',
    setCurrentModuleName: () => { },
    setCurrentModuleScope: () => { },
    currentModuleNavigationData: [],
    setCurrentModuleNavigationData: () => { },
    userCapabilities: [],
    setUserCapabilities: () => { },
    appConfiguration: [],
    setAppConfiguration: () => { },
    activeModules: [],
    setActiveModules: () => { }
})

export const AppContextProvider = ({ children, concreteconfig }) => {
    const [currentModuleName, setCurrentModuleName] = useState();
    const [currentModuleScope, setCurrentModuleScope] = useState();
    const [currentModuleNavigationData, setCurrentModuleNavigationData] = useState();


    const config = {
        currentModuleName,
        setCurrentModuleName,
        currentModuleScope,
        setCurrentModuleScope,
        currentModuleNavigationData,
        setCurrentModuleNavigationData,
        ...concreteconfig
    }

    return (
        <AppContext.Provider value={config}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;