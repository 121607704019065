import { React, createContext, useContext, useEffect, useState } from "react";
import { Drawer as JoyDrawer } from '@mui/joy';
import { Box, ModalClose } from '@mui/joy';
import { Text } from '../Typography';
import { Button } from '../Button';


import { v4 as uuidv4 } from 'uuid';
import { default as Dump } from '../../Dump';
import { BreadCrumb } from "./BreadCrumb";

export const DrawerContext = createContext()

export const DrawerProvider = ({ children, modules }) => {
    const [baseRoute, setBaseRoute] = useState([]);

    const [actives, setActives] = useState([]);

    const activate = (routeName, params, callback = () => { }, buttons) => {
        setActives([...actives, {
            routeName: routeName,
            params: params,
            callback: callback,
            uid: uuidv4(),
            buttons: buttons,
        }]);
    }

    const initBaseRoute = (routeName, params, callback = () => { }) => {
        if (actives.length !== 0) return;

        setBaseRoute({
            routeName: routeName,
            params: params,
            callback: callback,
            uid: uuidv4()
        });
    }

    const routeIsInstack = (routeName) => {
        return Array.isArray(actives) && actives.some(e => e.routeName === routeName);
    }

    const hasRoute = (routeName) => {
        return routes[routeName] !== undefined;
    }

    const close = () => {
        const stack = [...actives];
        const active = stack.pop();
        active && active.callback && active.callback();
        setActives([...stack]);
    }

    const handleClose = (uid, data) => {
        const stack = [...actives];
        const active = stack.find(item => item.uid === uid);
        active.callback && active.callback(data);
        const index = stack.findIndex(item => item.uid === uid);
        if (index > -1) {
            stack.splice(index, 1);
        }
        setActives([...stack]);
    };

    const [fullScreen, setFullScreen] = useState(false);
    const toggleFullscreen = () => {
        setFullScreen(!fullScreen);
    }

    const [routes, setRoutes] = useState({});

    useEffect(() => {
        if (!Array.isArray(modules)) return;
        const drawerRoutes = {}
        modules.map((m, k) => {
            if (!Array.isArray(m.drawerRoutes)) return;
            m.drawerRoutes.map((r, j) => {
                const fullPath = `${m.name}/${r.path}`;
                drawerRoutes[fullPath] = r.Component
            })
        });

        //console.log("drawerRoutes", drawerRoutes)
        setRoutes({ ...drawerRoutes });
    }, [modules]);

    return (
        <>
            <DrawerContext.Provider value={{ hasRoute, initBaseRoute, activate, routeIsInstack, close, baseRoute, actives, toggleFullscreen }}>
                {children}
                {actives.map((active, i, row) => {
                    const isLast = (i + 1 === row.length);
                    const Component = routes[active.routeName];
                    if (typeof Component === 'undefined') {
                        console.log(`Drawer route with name '${active.routeName} is not registered!'`, routes);
                        alert(`Drawer route with name '${active.routeName} is not registered!'`);
                        return null;
                    }
                    return (
                        <div key={i}>

                            <Drw uid={active.uid} onClose={handleClose} isLast={isLast} fullScreen={fullScreen} toggleFullscreen={toggleFullscreen} buttons={active.buttons}>
                                <Dump params={active.params} />
                                {Component ? (<Component isDrawer uid={active.uid} handleClose={handleClose} {...active.params} />) : (<>No view available</>)}
                            </Drw>
                        </div>
                    )
                })}
            </DrawerContext.Provider>
        </>
    );
};

export const useDrawer = () => {
    const context = useContext(DrawerContext);
    if (context === undefined) {
        throw new Error("useDrawer must be used within a DrawerProvider");
    }

    return {

        activate: context.activate,
        hasRoute: context.hasRoute,
        routeIsInstack: context.routeIsInstack,
        initBaseRoute: context.initBaseRoute,
        baseRoute: context.baseRoute,
        actives: context.actives,
        close: context.close,
        toggleFullscreen: context.toggleFullscreen,

    };
};

const Drw = ({ uid, children, isLast, fullScreen, toggleFullscreen, onClose = () => { }, buttons }) => {

    const [opened, setOpened] = useState(false);

    // we open aftyer rendering to show transitions
    useEffect(() => {
        setOpened(true);
    }, []);

    // close with transitions
    const handleClose = () => {
        setOpened(false);
        onClose(uid)
    };

    // after "closing transition" -> callback to un-render the component
    const handleTransationEnd = () => {
        if (opened) return;
        onClose(uid)
    };
    const fullwidth = !isLast || fullScreen;


    return (
        <JoyDrawer
            disableAutoFocus
            onTransitionEnd={handleTransationEnd}
            open={opened}
            onClose={handleClose}
            anchor={"right"}
            className={"drawer-wrapper drawer-wrapper-" + uid + " drawer-wrapper-fullwidth-" + fullwidth}
        >

            {children}
        </JoyDrawer>
    )
}


